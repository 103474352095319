import { createTheme } from '@mui/material';

// ref: https://www.figma.com/file/ZQjfwds0GrZlY0Hp5uwxep/%5Bwork%5D-DP-Portal-Renewal-%E6%A4%9C%E8%A8%8E%E7%94%A8?node-id=428%3A2441
const PRIMARY_MARINE = '#0C6993';
const LIGHT_MARINE = '#5097C4';
const DARK_MARINE = '#003F65';
// const BACKGROUND_MARINE = '#E0F2F7';

const SECONDARY_CORAL = '#F08B71';

const BLACK = '#232323';
const TEXT_GRAY = '#4E5B61';
const TEXT_DISABLED_GRAY = '#DADFE1';
const BORDER_GRAY = '#E6EBED';
// const BACKGROUND_GRAY = '#F8F8F8';
const WHITE = '#FFFFFF';

const theme = createTheme({
  typography: {
    fontFamily: ['Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif'].join(
      ','
    ),
  },
  palette: {
    common: {
      black: BLACK,
      white: WHITE,
    },
    primary: {
      main: PRIMARY_MARINE,
      light: LIGHT_MARINE,
      dark: DARK_MARINE,
    },
    secondary: {
      main: SECONDARY_CORAL,
    },
    text: {
      primary: BLACK,
      secondary: TEXT_GRAY,
      disabled: TEXT_DISABLED_GRAY,
    },
    background: {
      default: WHITE,
    },
    divider: BORDER_GRAY,
    info: {
      main: LIGHT_MARINE,
    },
    grey: {
      100: '#F6F7F8',
      500: '#4E5B61',
    },
  },
});

export default theme;
